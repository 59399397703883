<template>
  <div class="d-flex flex-column flex-root">
    <div
      class="login login-1 d-flex flex-column flex-lg-row flex-column-fluid bg-white login-signin-on"
      id="kt_login"
    >
      <div
        class="login-aside d-flex flex-column flex-row-auto"
        :style="backgroundColor"
      >
        <div class="d-flex flex-column-auto flex-column pt-lg-40 pt-15"></div>
        <div
          class="aside-img d-flex flex-row-fluid bgi-no-repeat bgi-position-y-bottom bgi-position-x-center"
          :style="{ backgroundImage: `url(${backgroundImage})` }"
        ></div>
      </div>
      <div
        class="login-content flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden p-7 mx-auto"
        style="width: 100%"
      >
        <div class="d-flex flex-column-fluid flex-center">
          <router-view></router-view>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Auth',

  computed: {
    backgroundImage() {
      return 'https://optimg.nyc3.digitaloceanspaces.com/app-img/svg/illustrations/login-visual-1.svg'
    },
    backgroundColor() {
      return 'background: rgb(105,176,204);background: radial-gradient(circle, rgba(105,176,204,1) 0%, rgba(127,159,220,1) 100%);'
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/sass/pages/login/login-1.scss';
</style>
